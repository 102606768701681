/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    a: "a",
    div: "div",
    h3: "h3",
    ul: "ul",
    li: "li",
    img: "img",
    em: "em",
    br: "br",
    strong: "strong"
  }, _provideComponents(), props.components), {HrefLangManager, TableOfContents, LinkWithQuery, StateCta, SideBySide, LandingPageCta, ThreeColCta} = _components;
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!LinkWithQuery) _missingMdxReference("LinkWithQuery", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  if (!StateCta) _missingMdxReference("StateCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  if (!ThreeColCta) _missingMdxReference("ThreeColCta", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/hearing-loss/'], ['en', 'https://www.hear.com/hearing-loss/'], ['en-US', 'https://www.hear.com/hearing-loss/'], ['en-CA', 'https://ca.hear.com/hearing-loss/']]
  }), "\n", React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The sounds of nature, conversations with family and friends, summer concerts — all things we enjoy because of our ability to hear. Our ears play an integral part in making activities enjoyable but are also a natural warning system that detects oncoming traffic, a possible home break-in or other potential dangers. We tend to take our hearing for granted, until it stops working."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "You shouldn’t wait for severe hearing loss to remind you how important it is to hear clearly. In fact, acting earlier is key to protecting the hearing you have left."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "one-in-six-has-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#one-in-six-has-hearing-loss",
    "aria-label": "one in six has hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "One in six has hearing loss"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Most people think that seniors are the only ones who have trouble hearing. However, this is a national problem that touches all age groups and one in six Americans. In most cases, ", React.createElement(LinkWithQuery, {
    to: "/hearing-loss/high-frequency/"
  }, "high frequencies"), " are the first ones that go missing. After that, the ability to hear is in a state of gradual decline. An important fact to remember is that the brain has less support when you can’t hear, which makes it difficult to distinguish sounds and words. Therefore, it’s very important you address any signs of poor hearing early on."), "\n", React.createElement(LinkWithQuery, {
    to: "/audiologists/"
  }, "Click here to learn more about audiologists and how they can help you!"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "causes-and-symptoms-of-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#causes-and-symptoms-of-hearing-loss",
    "aria-label": "causes and symptoms of hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Causes and symptoms of hearing loss"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Most people are usually unaware or in denial of their hearing loss until family and friends point it out — but this is the exact moment to do something about it. Studies show people wait an average of 7 years after noticing the first signs of hearing loss before acting on it. Once someone does make the important investment into hearing aids, they usually say, “I should’ve done this sooner,” realizing that ", React.createElement(LinkWithQuery, {
    to: "/hearing-aids/"
  }, "the use of hearing aids"), " would have significantly improved their quality of life sooner had they addressed it when they first noticed they were struggling to hear and understand."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing loss is often gradual and subtle. It can be a part of aging but can also be caused by things like repeated exposure to loud noises. Everyday life puts us in demanding listening environments, such as the workplace, a busy city or noisy restaurant. Many people have also experienced ", React.createElement(LinkWithQuery, {
    to: "/resources/hearing-aid-success/festival-season/"
  }, "temporary hearing difficulty after attending an extremely loud music event"), ". And in the last few decades, more people have been using headphones and earbuds almost exclusively to listen to music, which can increase the likelihood of hearing damage."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "It’s important to note that loss of hearing can be a result of a head injury, disease, infection, trauma, stress, diet. Genetics is also a common culprit."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "common-signs-of-hearing-loss-include",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#common-signs-of-hearing-loss-include",
    "aria-label": "common signs of hearing loss include permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Common signs of hearing loss include:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Difficulty following a conversation"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Difficulty understanding what people are saying in loud environments, such as busy restaurants, cafes, shops, etc."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Other people’s speech seem muffled"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Listening to the TV at a high volume"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Ringing in the ear (tinnitus)"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Group conversations are exhausting"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Saying “huh” or “what” all the time or asking people to repeat themselves"), "\n"), "\n", React.createElement(StateCta, {
    backgroundColor: "white"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "types-of-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#types-of-hearing-loss",
    "aria-label": "types of hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Types of hearing loss"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The medical community has identified three types of hearing loss: ", React.createElement(LinkWithQuery, {
    to: "/hearing-loss/conductive/"
  }, "conductive"), "\", ", React.createElement(LinkWithQuery, {
    to: "/hearing-loss/sensorineural/"
  }, "sensorineural"), ", and mixed. What they all have in common is that one’s hearing capacity is significantly reduced. This has a direct impact on a person’s quality of life, health and social interactions. What you do to manage your reduced ability to hear depends on its severity. Medical-grade hearing aids are currently the best solution to improve your hearing."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/types-of-hearing-loss.png",
    alt: "Types of Hearing Loss",
    className: "c-md-img"
  })), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing loss can affect many aspects of your life. Communication with family and friends, business meetings, telephone calls, or spending time with your children can become a challenge. In some cases, you can even become socially isolated. Family and friends, unfortunately, might not fully understand your hearing situation, which can lead to misunderstandings and feelings of frustration. Therefore, it is essential for the people around you — spouse, grandchildren, children, colleagues, friends — to understand what you are experiencing."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "why-are-hearing-aids-the-best-form-of-treatment",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#why-are-hearing-aids-the-best-form-of-treatment",
    "aria-label": "why are hearing aids the best form of treatment permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Why are hearing aids the best form of treatment?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing is an extremely important aspect of human perception and our overall health. We depend on our hearing to understand speech, listen to the sounds we love, and enjoy spending time with our family. There is no medicine a doctor can prescribe that can cure this kind of hearing disorder."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The best treatment is the use of medical-grade hearing aids that are programmed to your specific hearing needs by a qualified hearing care professional. Not only will everything around you sound better and clearer, but you’ll be able to fully participate in conversations again and your overall wellbeing will improve. When your hearing aids are programmed by a professional, they’ll never be too loud, so you won’t have to worry about additional damage to your hearing."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "consequences-of-hearing-difficulties",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#consequences-of-hearing-difficulties",
    "aria-label": "consequences of hearing difficulties permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Consequences of hearing difficulties"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Study after study shows that people feel fatigued, unhappy, isolated and depressed if they leave their hearing loss untreated. The ability to concentrate, participate in conversations, self-esteem, and speech comprehension are significantly reduced."), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/v1673278563/assets/na/seo-website/Dr-Emily-Smith.png"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.em, null, "“Recent research by Dr. Frank Lin at Johns Hopkins University has found that untreated hearing loss increases the incidence of cognitive decline, dementia, and Alzheimer’s Disease. The brain requires consistent sound stimulation to remain active and healthy. By not wearing hearing aids, people living with hearing loss are missing out on richer experiences, whether they are cultural, professional or social.“"), "\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", React.createElement(_components.em, null, React.createElement(_components.strong, null, "– Emily Smith, Au.D., Lead Audiologist")))), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.em, null, "If you suspect a sign of hearing loss, we’re here to help you get your life and freedom back. Contact us to enroll in our no-risk 45-day hearing aid trial and schedule a hearing appointment with one of our local hearing aid providers in your area.")), "\n", React.createElement(LandingPageCta, {
    copy: "START NO-RISK TRIAL",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "resources",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resources",
    "aria-label": "resources permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Resources"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Learn everything you need to know about hearing aids and hearing loss."), "\n", React.createElement(ThreeColCta, {
    ImgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/women-with-tablet.jpg",
    ContentTitle1: "Hearing Test",
    ContentCopy1: "Check how is your hearing in less than 5 minutes.",
    Url1: "/hearing-test/",
    ImgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-behind-ear-blur.jpg",
    ContentTitle2: "Value & Price",
    ContentCopy2: "Are hearing aids worth the cost? We asked an expert.",
    Url2: "/hearing-aids/prices/",
    ImgSrc3: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ax-grey-blur.jpg",
    ContentTitle3: "Hearing Aids",
    ContentCopy3: "Connect you to the world around you with our wide range of devices.",
    Url3: "/resources/hearing-aids/"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
